import React, { useState, useRef, useEffect, useContext } from 'react';
import './EvaluationPage.css';
import MapTable from '../../webAppComponents/MapTable/MapTable'
import FilterBy from '../../webAppComponents/FilterBy/FilterBy';
import axios from 'axios';
import Sidebar from '../../webAppComponents/Sidebar/Sidebar';
import AuthContext from '../../context/AuthContext';
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';

import BckLogo from '../../webAppAssets/BackgroundLogo.svg';

import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger'

function Avaliação() {

  const { t } = useTranslation(['evaluations-page']);

  const navigate = useNavigate();
  const location = useLocation();
  const [rendering, setRendering] = useState(true);
  const [playerData, setPlayerData] = useState([]);
  const [firstVisibleColumnIndex, setFirstVisibleColumnIndex] = useState(0);
  const [filterOptions, setFilterOptions] = useState([{ label: t('all-players'), value: "" }])
  const [selectedPlayer, setSelectedPlayer] = useState("  ")
  const handleFilterChange = (e) => {
    setSelectedPlayer(e)
  }
  const columnNames = [
    t('Position'), t('Date'), t('Quartile'), t('Age'), t('Height P'), t('Height S'), t('Large P'), t('Weight'), t('BMI'), "PHV",
    "CMJ", "CMJ Z", "SJ", "10M", "10M Z", "20M", "20M Z", "30M", "30M Z", t("agility"), `${t("agility")} Z`, "YO-YO P", "YO-YO Z"
  ];
  const mappedColumns = {
    "id": "id",
    "ItemName": "player",
    [columnNames[0]]: "position",
    [columnNames[1]]: "evaluation_form",
    [columnNames[2]]: "caurt_nac",
    [columnNames[3]]: "age",
    [columnNames[4]]: "heightP",
    [columnNames[5]]: "heightS",
    [columnNames[6]]: "largeP",
    [columnNames[7]]: "weight",
    [columnNames[8]]: "imc",
    [columnNames[9]]: "phv",
    [columnNames[10]]: "cmj",
    [columnNames[11]]: "cmjZ",
    [columnNames[12]]: "sj",
    [columnNames[13]]: "ten_meters",
    [columnNames[14]]: "ten_metersZ",
    [columnNames[15]]: "twenty_metersL",
    [columnNames[16]]: "twenty_metersLZ",
    [columnNames[17]]: "thirty_meters",
    [columnNames[18]]: "thirty_metersZ",
    [columnNames[19]]: "zig_zag",
    [columnNames[20]]: "zig_zagZ",
    [columnNames[21]]: "yo_yoP",
    [columnNames[22]]: "yo_yoZ"
}

  const { authTokens } = useContext(AuthContext);

  // Define the headers with the Bearer token
  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };

  useEffect(() => {
    // Make a GET request with the specified headers
    axios.get(process.env.REACT_APP_BACKEND + 'evaluations'+ `?player=${location.state && rendering ? location.state.player.user.id : selectedPlayer}`, { headers })
      .then((response) => {
        // Handle the successful response here
        setPlayerData(response.data)
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error('Error:', error);
      });
      setRendering(false)
  }, [location.state, selectedPlayer])

  useEffect(() => {
    const getPlayers = async () => {
      try {
        const { data } = await axios.get(process.env.REACT_APP_BACKEND + "users/list-players", { headers });
        // Create a new array of filter options
        let newFilterOptions = data.map(player => ({
          label: `${player.user.first_name} ${player.user.last_name}`,
          value: player.user.id
        }));
        // Add the default option
        newFilterOptions.unshift({ label: t('all-players'), value: "" });

        setFilterOptions(newFilterOptions);

      } catch (error) {
        console.error('Error:', error);
      }
    };
    getPlayers();
  }, []);

  const createEvaluationRoute = () => {
    navigate('/webapp/evaluations/create')
  }

  const placeBackgroundImages = () => {
    const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
    const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
    const bckLogo1Height = bckLogo1.offsetHeight;
    bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
    bckLogo2.style.top = `0%`;
    const bckLogo1Width = bckLogo1.offsetWidth;
    bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
    bckLogo2.style.left = `75%`;
  }

  useEffect(() => {
    placeBackgroundImages();
    window.addEventListener('resize', placeBackgroundImages);
    return () => {
        window.removeEventListener('resize', placeBackgroundImages);
    }
  }, []);

  return (
    <div className="webapp-evaluations-page-scroll">
      <Sidebar selectedOption="evaluations" />
      <div className="webapp-evaluations-page">
        <TrainingsPageChanger
            selectedPage={0}
            pages={[
                { label: t('Total'), path: '/webapp/evaluations' },
                { label: t('Statistics'), path: '/webapp/evaluations-statistics' },
            ]}
        />
        <div className="webapp-create-team-background-images">
            <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
            <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
        </div>
        <div className="webapp-evaluations-page-header">
          <h1>{t('Total Evaluations')}</h1>
          <button onClick={createEvaluationRoute}>+ {t('Add Evaluation')}</button>
        </div>

        <div className='webapp-evaluations-page-subheader'>
          <FilterBy options={filterOptions} onChange={handleFilterChange} />
        </div>
        <div className='webapp-evaluations-page-table'>
          <MapTable ItemName={t("Player")} detailRoute={'/webapp/player-evaluation/'} Data={playerData} columnNames={columnNames} mappedColumns={mappedColumns} t={t}/>
        </div>
      </div> 

    </div>
  );
}

export default Avaliação;
